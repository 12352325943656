import { environment as defaultEnvironment } from './environment.default';
import { games } from './gamingpodium'

//this is the test brand for our games

export const environment = {
  ...defaultEnvironment,
  games,
  production: false, //if we want to see it in test mode + angular.json/build optimization to false

  platform: "gamingpodium",

  rivalName: "gamingpodium",
  casinoId: 70,
  GA_TRACKING: "", //todo get a tracker
  licenseNo: "",

  rivalChatGroupName: "Gaming Podium",  //TODO
  // chatSystem: "other", //from default todo find out later

  prettyName: "GamingPodium",
  contactEmail: "support@gamingpodium.net",  //todo
  docsEmail: "support@gamingpodium.net",  //todo

  apiUrl: 'https://gp.0x6e.com',
  assetsPath: "assets/brands/testbrand", //because of this we have to add assetspath to other brands using this config

  affiliatedGames: [], //to show aff games on GP brands


  //SEO - from default
  // metaDescription: "",
  // logoName: "logo.png",
  // logoAltText: ""

};


