import { environment as gamingPodiumEnvironment } from './environment.gamingpodium';

export const environment = {
  ...gamingPodiumEnvironment,

  rivalName: "slothorizon",
  casinoId: 1,
  // GA_TRACKING: "", //todo get a tracker

  rivalChatGroupName: "Slot Horizon",
  prettyName: "Slot Horizon",
  contactEmail: "support@slothorizon.com", //todo
  docsEmail: "support@slothorizon.com", //todo

  chatSystem: "tawk",
  tawk_propertyId: "6696d633becc2fed69266866",
  tawk_widgetId: "1i2uj9f3t",
  tawk_apiKey: "", //todo

  assetsPath: "assets/brands/slothorizon", //or assetsPath: undefined

  //SEO - from default
  // metaDescription: "",
  // logoName: "logo.png",
  // logoAltText: ""

};


